<template>
  <div class="guide_contain" v-if="!isShowWhitePage">
    <div class="guide_text">请使用微信扫一扫打开</div>
    <div class="guide_img">
      <!-- 图片 -->
      <img class="img" src="../../assets/imgs/guide/guide.png" alt="引导图" />
    </div>
  </div>
</template>

<script>
const axios = require("axios");
import { getQueryString } from "@/utils/utils";

export default {
  name: "infocode",
  computed: {
    isShowWhitePage() {
      if (
        this.path &&
        this.path.indexOf("pages/info-entry/info-entry") !== -1
      ) {
        if (this.goodsMasterId) {
          return true;
        } else if (!this.requstComplete) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
  },
  data() {
    return {
      path: "",
      goodsMasterId: "",
      requstComplete: false,
    };
  },
  created() {
    this.path = this.$route.query.path || "";
    if (this.path && this.path.indexOf("pages/info-entry/info-entry") !== -1) {
      this.getKukeCloudInfo();
    }
  },
  methods: {
    getKukeCloudInfo() {
      this.requstComplete = false;
      const skuId = getQueryString(
        decodeURIComponent(window.location.href),
        "skuId"
      );
      axios
        .post(
          `${process.env.VUE_APP_BOOK_TRANSFER_API}/kukecoregoods/bookDataMigration/oldConvertNew`,
          {
            idType: "1",
            oldId: `${skuId}`,
          }
        )
        .then((res) => {
          if (res && res.data && res.data.code === "10000") {
            let data = res.data.data;
            if (data && data.goodsMasterId) {
              this.goodsMasterId = data.goodsMasterId || "";
              window.location.href = `${process.env.VUE_APP_BOOK_KUKECLOUD_URL}/course/${this.goodsMasterId}`;
            }
          }
          this.requstComplete = true;
        })
        .catch(() => {
          this.requstComplete = true;
        });
    },
  },
};
</script>

<style scoped>
.guide_contain {
  width: 100%;
  height: 900px;
  background: url("../../assets/imgs/guide/background.png") no-repeat center;
}
.guide_text {
  padding-top: 249px;
  padding-bottom: 58px;
  /* padding-top: 16%;
  padding-bottom: 3%; */
  height: 70px;
  font-size: 50px;
  font-weight: 600;
  color: #e62129;
  line-height: 70px;
  text-align: center;
}
.img {
  width: 85%;
}
</style>
